.formBox[data-v-2f5f51aa] {
  position: absolute;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.formTitleBox[data-v-2f5f51aa] {
  width: calc(100% - 24px);
  border-bottom: 1px solid #bbbbbb;
  padding: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.leftBack[data-v-2f5f51aa] {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.backBox[data-v-2f5f51aa] {
  position: relative;
  line-height: 28px;
}
.backBox[data-v-2f5f51aa]:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 16px;
  right: -20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #dcdfe6;
}
.rightBtn[data-v-2f5f51aa] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.formContentBox[data-v-2f5f51aa] {
  padding: 12px;
  height: calc(100% - 76px);
}
.formMain[data-v-2f5f51aa] {
  width: 100%;
  overflow: auto;
  height: 100%;
}
.formTopic[data-v-2f5f51aa] {
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid rgb(187, 187, 187);
  font-weight: 600 !important;
  font-size: 18px;
}
.shuttleBackBox[data-v-2f5f51aa] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.shutleBack[data-v-2f5f51aa] {
  width: 27%;
  background: #ffffff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
}
.leftRightBtn[data-v-2f5f51aa] {
  margin: 211px 12px;
}
.shutleTitle[data-v-2f5f51aa] {
  width: 100%;
  background-color: #f5f7fa;
  height: 40px;
  line-height: 40px;
  text-indent: 12px;
  border-bottom: 1px solid #ebeef5;
  font-size: 16px;
}
.shutContent[data-v-2f5f51aa] {
  padding: 6px;
  height: 400px;
  overflow: auto;
}
.shutImg[data-v-2f5f51aa] {
  width: 40px;
  height: 40px;
  display: block;
  margin: 207px 12px;
}
.deptBox[data-v-2f5f51aa] {
  height: 40px;
  line-height: 40px;
  color: #333333;
  border-bottom: 1px solid #ebeef5;
  font-size: 13px;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.deptBox span[data-v-2f5f51aa] {
  width: 80%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}